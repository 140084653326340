<script setup>
import { transl } from "@/composables/useTranslation";

const props = defineProps(["goback"]);
const emit = defineEmits(["goback"]);
const attrs = useAttrs();

const menu = ref(false);

function goBack() {
  emit("goback");
}
</script>
<template>
  <div class="nav-header">
    <v-btn icon class="icon-size" @click="goBack()"
      ><img src="/icons/arrow.svg" alt=""
    /></v-btn>

    <!-- <v-btn @click="menu = true" icon="sm" class="icon-size">
      <div class="menu">
        <span></span>
        <span></span>
        <span></span>
      </div>    
    </v-btn> -->

    <div class="nav-title">{{ transl(attrs.label) }}</div>
    <div class="icon-size"><slot name="append"></slot></div>
  </div>
</template>
<style scoped>
.nav-header {
  min-height: var(--header-height);
  height: var(--header-height);
  background-color: var(--root-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--root-dark);
  font-weight: bold;
  font-size: 21px;
  /* position: sticky; */
  top: 0;
  z-index: 1;
  position: sticky;
}

.nav-title {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
}

.icon-size {
  min-width: 40px;
  min-height: 40px;
  /* background-color: green; */
}
.icon-size img {
  width: 18px;
  filter: brightness(0) saturate(100%);
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23px;
  height: 15px;
}
.menu span {
  background: #292937;
  height: 3px;
  border-radius: 4px;
  width: 100%;
}

.menu span:nth-child(2n + 1) {
  width: 70%;
  transition: all 0.3s ease;
}
.menu span:nth-child(1) {
  margin-right: auto;
}
.menu span:nth-child(3) {
  margin-left: auto;
}

.menu:hover span:nth-child(2n + 1) {
  width: 100%;
}
</style>
